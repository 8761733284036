import { ReactElement } from 'react';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import classNames from 'classnames';

export type TableColumn<T> = {
  id: keyof T;
  label: string;
  renderer: (model: T) => ReactElement;
};

export enum RowColoring {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

type SortableTableRowProps<T> = {
  columns: TableColumn<T>[];
  model: T;
  highlighted: boolean;
  onClick: () => void;
  disabled: boolean;
  coloring?: RowColoring;
  isLoading?: boolean;
};

export const TableRow = <T extends unknown>({
  columns,
  model,
  highlighted,
  onClick,
  disabled,
  coloring,
  isLoading = false,
}: SortableTableRowProps<T>): ReactElement | null => {
  const handleClickOnRow = () => {
    if (disabled || isLoading) {
      return;
    }

    onClick();
  };

  const cells = columns.map((column, index) => {
    const render = column.renderer;
    return (
      <td
        key={index}
        data-field={column}
      >
        {isLoading ? (
          <ContentLoader data-testid={'content-loader'} />
        ) : (
          render(model)
        )}
      </td>
    );
  });

  const classes = classNames(
    `${!isLoading ? 'cursor-pointer' : ''}`,
    `${highlighted && !isLoading ? 'active' : ''}`,
    `${disabled ? 'disabled' : ''}`,
    `${coloring ? coloring : ''}`,
  );

  return (
    <tr
      className={classes}
      onClick={handleClickOnRow}
    >
      {cells}
      <td className="padding-0 text-center">
        <span
          data-testid="table-row-click-indicator"
          className={
            'margin-left-auto rioglyph rioglyph-chevron-right text-color-primary'
          }
        />
      </td>
    </tr>
  );
};
