import { FeatureTeaser } from '../FeatureTeaser';
import edenredImage from '../../../assets/images/landingPage/edenred.jpg';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { useLocale } from '../../../i18n/useLocale';

const LOCALE_GERMAN = 'de-DE';

export const EdenredTeaser = () => {
  const { locale } = useLocale();

  return (
    <FeatureTeaser
      image={edenredImage}
      headline={
        <CustomFormattedMessage id={'landing.partnerTeaser.edenred.headline'} />
      }
      content={
        <CustomFormattedMessage id={'landing.partnerTeaser.edenred.body'} />
      }
      onClick={() => {
        const link =
          locale === LOCALE_GERMAN
            ? 'https://web.uta.com/uta-edenred-man-simple-pay'
            : 'https://web.uta.com/en/uta-edenred-man-simple-pay';

        window.open(link, '_blank', 'noopener');
      }}
    />
  );
};
