import React from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { DIALOG_DATA_COLOR } from './ServiceConfigurationDialog';
import { MaskedCardNumber } from '../../../../components/MaskedCardNumber';
import { PaymentMethod } from '../../../../codegen/vehicles';
import { ServiceCardRepresentation } from '../../../../components/dataRepresentation/ServiceCardRepresentation';
import { PAYMENT_METHOD_STATE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import { paymentMethodStateTextColors } from '../../../../components/colors/paymentMethodStateTextColors';

type ServiceCardDetailsProps = {
  paymentMethod?: PaymentMethod;
};

export const ServiceCardDetails: React.FC<ServiceCardDetailsProps> = ({
  paymentMethod,
}) => {
  return (
    <div
      className={`${DIALOG_DATA_COLOR} grid-colspan-8 text-light text-size-14 padding-left-10 padding-top-5
      padding-right-5`}
    >
      {paymentMethod && (
        <>
          {paymentMethod.paymentPartner && (
            <div className="row">
              <div className="col-xs-6">
                <CustomFormattedMessage id={'common.card.type'} />
              </div>
              <div className={'col-xs-6 text-right'}>
                <ServiceCardRepresentation
                  paymentPartner={paymentMethod.paymentPartner}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className={'col-xs-6'}>
              <CustomFormattedMessage id={'common.card.number'} />
            </div>
            <div className={'col-xs-6 text-right'}>
              <MaskedCardNumber lastFourDigits={paymentMethod.lastFourDigits} />
            </div>
          </div>
          <div className="row">
            <div className={'col-xs-6'}>
              <CustomFormattedMessage id={'common.card.expiryDate'} />
            </div>
            <div className={'col-xs-6 text-right'}>
              <span>{paymentMethod.expiry}</span>
            </div>
          </div>
          <div className="row">
            <div className={'col-xs-6'}>
              <CustomFormattedMessage id={'common.paymentMethod.status'} />
            </div>
            <div
              className={`col-xs-6 text-right ${paymentMethodStateTextColors[paymentMethod.state]}`}
            >
              <CustomFormattedMessage
                id={
                  PAYMENT_METHOD_STATE_TO_TRANSLATION_KEY[paymentMethod.state]
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
