import TeaserContainer from '@rio-cloud/rio-uikit/TeaserContainer';
import bookingsImage from '../../assets/images/landingPage/feature-booking.svg';
import transactionsImage from '../../assets/images/landingPage/feature-transactions.svg';
import dashboardImage from '../../assets/images/landingPage/feature-dashboard.svg';
import cardManagementImage from '../../assets/images/landingPage/feature-card-management.png';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { FeatureTeaser } from './FeatureTeaser';
import {
  BOOKINGS_ROUTE,
  DASHBOARD_ROUTE,
  ONBOARDING_ROUTE,
  TRANSACTIONS_ROUTE,
} from '../../routes/routes';
import { useNavigate } from 'react-router-dom';
import { LiveMonitorTeaser } from './partnerTeasers/LiveMonitorTeaser';
import { DriverAppTeaser } from './partnerTeasers/DriverAppTeaser';
import { EdenredTeaser } from './partnerTeasers/EdenredTeaser';

export const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <h1 className={'text-center margin-top-0 margin-bottom-25'}>
        <CustomFormattedMessage id={'landing.headline'} />
      </h1>
      <div data-testId={'landing-page-feature-teasers'}>
        <TeaserContainer
          teaserPerRow={4}
          className={'justify-content-center'}
        >
          <FeatureTeaser
            headline={
              <CustomFormattedMessage
                id={'base.header.navigationLinks.onboarding'}
              />
            }
            content={
              <CustomFormattedMessage
                id={'landing.featureTeaser.cardManagement.body'}
              />
            }
            onClick={() => navigate(ONBOARDING_ROUTE)}
            image={cardManagementImage}
          />
          <FeatureTeaser
            headline={
              <CustomFormattedMessage
                id={'base.header.navigationLinks.dashboard'}
              />
            }
            content={
              <CustomFormattedMessage
                id={'landing.featureTeaser.dashboard.body'}
              />
            }
            onClick={() => navigate(DASHBOARD_ROUTE)}
            image={dashboardImage}
          />
          <FeatureTeaser
            headline={
              <CustomFormattedMessage
                id={'base.header.navigationLinks.transactions'}
              />
            }
            content={
              <CustomFormattedMessage
                id={'landing.featureTeaser.transactions.body'}
              />
            }
            onClick={() => navigate(TRANSACTIONS_ROUTE)}
            image={transactionsImage}
          />
          <FeatureTeaser
            headline={
              <CustomFormattedMessage
                id={'base.header.navigationLinks.bookings'}
              />
            }
            content={
              <CustomFormattedMessage
                id={'landing.featureTeaser.bookings.body'}
              />
            }
            onClick={() => navigate(BOOKINGS_ROUTE)}
            image={bookingsImage}
          />
        </TeaserContainer>
      </div>

      <h2 className={'text-center margin-top-20 margin-bottom-20'}>
        <CustomFormattedMessage id={'landing.subHeadline.partnerServices'} />
      </h2>
      <div data-testId={'landing-page-partnerTeasers'}>
        <TeaserContainer
          teaserPerRow={4}
          className={'justify-content-center'}
        >
          <LiveMonitorTeaser />
          <DriverAppTeaser />
          <EdenredTeaser />
        </TeaserContainer>
      </div>
    </>
  );
};
