import React from 'react';
import { useVehicleDialogFormContext } from './ServiceConfigurationForm';
import {
  enhancedApi,
  PaymentMethod,
  PaymentMethodState,
  ServiceActivation,
  useRemovePaymentMethodMutation,
} from '../../../../codegen/vehicles';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { ServiceCardDetails } from './ServiceCardDetails';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { filter, forEach, remove } from 'lodash';
import { useAppDispatch } from '../../../../state/hooks';
import { openRemoveConfirmationDialog } from '../../state/onboardingUiSlice';

type ServiceCardPanelProps = {
  vehicleId: string;
  paymentMethod: PaymentMethod;
};

export const ServiceCardPanel: React.FC<ServiceCardPanelProps> = ({
  vehicleId,
  paymentMethod,
}) => {
  const dispatch = useAppDispatch();
  const {
    removePaymentMethod: removePaymentMethodFromForm,
    getServiceActivations,
  } = useVehicleDialogFormContext();
  const [removePaymentMethod] = useRemovePaymentMethodMutation();

  const handleRemoveCard = (paymentMethodId: string) => {
    const serviceActivations = getServiceActivations();
    const serviceActivationsWithPaymentMethod: ServiceActivation[] = [];
    map(serviceActivations, (serviceActivation: ServiceActivation) => {
      const a = filter(serviceActivation.paymentMethodAssignments, {
        paymentMethodId,
      });
      if (!isEmpty(a)) {
        serviceActivationsWithPaymentMethod.push(serviceActivation);
      }
    });

    if (!isEmpty(serviceActivationsWithPaymentMethod)) {
      dispatch(
        openRemoveConfirmationDialog({
          linkedServiceActivations: serviceActivationsWithPaymentMethod,
          paymentMethodId,
        }),
      );
    } else {
      doRemovePaymentMethod(paymentMethodId);
    }
  };

  const doRemovePaymentMethod = (paymentMethodId: string) => {
    removePaymentMethod({
      vehicleId,
      paymentMethodId,
    }).then((result) => {
      if ('error' in result) {
        return;
      }
      removePaymentMethodFromForm(paymentMethodId);
      dispatch(
        enhancedApi.util.updateQueryData(
          'getVehicles',
          undefined,
          (draftPosts) => {
            draftPosts.items = draftPosts.items.map((vehicle) => {
              if (vehicle.id === vehicleId) {
                remove(vehicle.paymentMethods, (pm) => {
                  return pm.id === paymentMethodId;
                });
                forEach(vehicle.serviceActivations, (serviceActivation) => {
                  if (
                    serviceActivation.paymentMethodAssignments[0]
                      ?.paymentMethodId === paymentMethodId
                  ) {
                    serviceActivation.enabled = false;
                    serviceActivation.paymentMethodAssignments = [];
                  }
                });
              }
              return vehicle;
            });
          },
        ),
      );
    });
  };

  return (
    <>
      <div
        className="width-100pct"
        data-testid={'onboarding-dialog-body-service-card-panel'}
      >
        <div
          className="
        display-flex
        border
        margin-top-10
        padding-bottom-10
        padding-top-10
        padding-right-20
        justify-content-between"
        >
          <div className="display-flex align-items-center padding-left-10">
            <Button
              data-testid={'onboarding-dialog-body-removeFuelCardButton'}
              bsStyle={Button.MUTED}
              iconOnly
              onClick={() => handleRemoveCard(paymentMethod.id)}
              disabled={
                paymentMethod.state === PaymentMethodState.OnboardingPending
              }
            >
              <span className="rioglyph rioglyph-trash" />
            </Button>
          </div>
          <div className="width-300">
            <ServiceCardDetails paymentMethod={paymentMethod} />
          </div>
        </div>
      </div>
    </>
  );
};
